import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ZimmerbelegungFilter',
  props: {
    filterConfig: {
      type: Object,
      required: true
    },
    termine: {
      type: Array,
      required: true
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    hasFilters: function hasFilters() {
      return !!this.filterConfig.filters && this.filterConfig.filters.length > 0 || !!this.filterConfig.filterGenerator;
    }
  },
  methods: {
    filterClicked: function filterClicked(activeFilter) {
      console.log('activeFilter', activeFilter);
      this.$emit('active-filter-changed', activeFilter);
    },
    sortDirectionChanged: function sortDirectionChanged(sortDirection) {
      this.$emit('sort-direction-changed', sortDirection);
    },
    updateDynamicData: function updateDynamicData() {
      this.$emit('update-dynamic-data');
    }
  }
});