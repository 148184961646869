import { defineComponent } from 'vue';
import { settingsStore } from '@/state/settingsStore';
var defaultSlot = {
  start: 0,
  ende: 0,
  dauer: 0,
  percentage: 0
};
export default defineComponent({
  props: {
    termin: {
      type: Object,
      required: true
    }
  },
  computed: {
    wzs: function wzs() {
      var _a;

      return Object.assign(Object.assign({}, defaultSlot), (_a = this.termin.zeitslots) === null || _a === void 0 ? void 0 : _a.warteZimmerSlot);
    },
    bzs: function bzs() {
      var _a;

      return Object.assign(Object.assign({}, defaultSlot), (_a = this.termin.zeitslots) === null || _a === void 0 ? void 0 : _a.behandlungsZimmerSlot);
    },
    bs: function bs() {
      var _a;

      return Object.assign(Object.assign({}, defaultSlot), (_a = this.termin.zeitslots) === null || _a === void 0 ? void 0 : _a.behandlungsSlot);
    },
    hasTerminzeit: function hasTerminzeit() {
      return this.termin.wartezimmerzeit || this.termin.behandlungszimmerzeit || this.termin.behandlungsstartzeit;
    },
    hasBehandlungszimmerzeit: function hasBehandlungszimmerzeit() {
      return !settingsStore.getters.isZ1;
    },
    settingsWartezeiten: function settingsWartezeiten() {
      return settingsStore.state.settings.wartezeiten;
    }
  }
});