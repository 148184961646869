import { defineComponent } from 'vue';
import { GeschlechtType } from '@rose/types';
import OpenInCharly from '@/components/OpenInCharly.vue';
export default defineComponent({
  components: {
    OpenInCharly: OpenInCharly
  },
  props: {
    patient: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      GeschlechtType: GeschlechtType
    };
  },
  methods: {
    goToPatient: function goToPatient(termin) {
      void this.$router.push({
        name: 'patient',
        params: {
          patid: termin.patid
        }
      });
    },
    logEntry: function logEntry(termin) {
      console.log(termin);
    },
    onOpenPatient: function onOpenPatient() {
      this.$emit('open-patient');
    }
  }
});